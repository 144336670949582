export default function usePopup () {
  const isOpen = ref(false)
  let openCallback: Function | undefined

  const setOpenCallback = (callback?: Function) => {
    openCallback = callback
  }

  const open = () => {
    isOpen.value = true

    openCallback && openCallback()
  }

  const close = () => {
    isOpen.value = false
  }

  const addFixedUi = () => {
    const htmlElement = document.querySelector('html')

    if (htmlElement?.style) {
      const pageY = window.pageYOffset

      htmlElement.setAttribute('scrollY', pageY.toString())

      htmlElement.style.overflow = 'hidden'
      htmlElement.style.position = 'fixed'
      htmlElement.style.left = '0px'
      htmlElement.style.right = '0px'
      htmlElement.style.bottom = '0px'
      htmlElement.style.top = `-${pageY}px`
    }
  }

  const removeFixedUi = () => {
    const htmlElement = document.querySelector('html')

    if (htmlElement?.style) {
      if (htmlElement.getAttribute('scrollY')) {
        htmlElement.style.removeProperty('overflow')
        htmlElement.style.removeProperty('position')
        htmlElement.style.removeProperty('top')
        htmlElement.style.removeProperty('left')
        htmlElement.style.removeProperty('right')
        htmlElement.style.removeProperty('bottom')

        window.scrollTo(0, Number(htmlElement.getAttribute('scrollY')))

        htmlElement.removeAttribute('scrollY')
      }
    }
  }

  let scrollPos: number
  // 스크롤 비활성화 함수
  const disableScroll = (): void => {
    // 현재 페이지의 스크롤 위치를 저장
    scrollPos = document.documentElement.scrollTop || document.body.scrollTop || 0

    // 스크롤 이벤트에 대한 리스너 추가
    window.addEventListener('scroll', preventScroll)
  }

  // 스크롤을 방지하는 함수
  const preventScroll = (_e: Event): void => {
    window.scrollTo({
      top: scrollPos,
      behavior: 'instant'
    })
  }

  // 스크롤 활성화 함수
  const enableScroll = (): void => {
    // 스크롤 이벤트 리스너 제거
    window.removeEventListener('scroll', preventScroll)
  }

  watch(() => isOpen.value, (value) => {
    if (value) {
      disableScroll()
    } else {
      enableScroll()
    }
  })

  return {
    isOpen,
    setOpenCallback,
    open,
    close,
    addFixedUi,
    removeFixedUi
  }
}
